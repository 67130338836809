

















































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { IPaginate, ITransferAmount } from '@/types/types';
import { loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { currencyFormat } from '@/filters/currencyFormat';
import { Watch } from 'vue-property-decorator';

const paymentDetailsModule = namespace('paymentDetails');

@Component({
  name: 'History',
  components: {
    ToolBar: loadWidget('widgets/ToolBar'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    currencyFormat,
  },
})
export default class History extends mixins(WidgetMixins, DialogMixins) {
  @paymentDetailsModule.Getter('getAllTransactionHistory')
  transfers!: ITransferAmount & { status: string };
  @paymentDetailsModule.Getter('getPagination') paginatedObj!: Pick<IPaginate, 'itemsPerPage' | 'total' | 'page'> & {
    isPaginated: boolean;
  };

  pagination: Pick<IPaginate, 'itemsPerPage' | 'total' | 'page'> & {
    isPaginated: boolean;
  } = {
    itemsPerPage: 0,
    total: 0,
    page: 1,
    isPaginated: true,
  };

  options: any = {};
  itemsPerPageOptions: number[] = [5, 10, 20, 30, 50, 100];

  search: string = '';

  headers: Array<{ text: string; value: string; sortable?: boolean }> = [
    {
      text: 'Date',
      value: 'createdAt',
    },
    {
      text: 'Amount',
      value: 'amount',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Action',
      value: 'action',
    },
  ];

  transfer_code: string = '';
  userId: string = '';
  transferId: string = '';

  @Watch('paginatedObj')
  onPaginationChange(
    payload: Pick<IPaginate, 'itemsPerPage' | 'total' | 'page'> & {
      isPaginated: boolean;
    },
  ) {
    this.pagination = { ...payload };
  }

  @Watch('options')
  moveToNext(payload: IPaginate) {
    const { itemsPerPage, page } = payload;
    this.$store.dispatch('paymentDetails/transactionList', {
      limit: itemsPerPage,
      page,
    });
  }

  getTransactions() {
    this.$store.dispatch('paymentDetails/transactionList', {
      page: 1,
      limit: 20,
    });
  }
  created(): void {
    this.getTransactions();
  }
}
